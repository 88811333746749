@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
